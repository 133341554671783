import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default function BlogIndexPage() {
  return (
    <Layout>
      <div style={{ display: 'grid' }}>
        <StaticImage
          style={{
            gridArea: '1/1',
            maxHeight: 400,
          }}
          layout="fullWidth"
          alt="Folsom Twilight Concert Series"
          src="../../img/pages/folsom-rainbow-bridge-bill-akers.jpg"
          formats={['auto', 'webp']}
        />
        <div
          style={{
            gridArea: '1/1',
            position: 'relative',
            placeItems: 'center',
            display: 'grid',
          }}
        >
          <header style={{ margin: '0 20px' }}>
            <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow:
                'rgb(241, 108, 102) 0.5rem 0px 0px, rgb(241, 108, 102) -0.5rem 0px 0px',
              backgroundColor: 'rgb(241, 108, 102)',
              color: 'white',
              padding: '1rem',
            }}
          >
            Latest Stories
          </h1>
          </header>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="content">
            <BlogRoll />
          </div>
        </div>
      </section>
    </Layout>
  );
}
